/**
 * Home Component - Startseite des Vereins
 * 
 * Diese Komponente zeigt die Startseite des Vereins an. Sie enthält:
 * - Aktuelles Titelbild
 * - Willkommenstext
 * - Neueste Nachrichten
 * - Hallenplan (als Modal)
 * - Sponsoren-Sektion
 * 
 * Integration mit Contentful:
 * - Titelbild und Text werden aus Contentful geladen
 * - Nachrichten werden dynamisch aus Contentful bezogen
 * - Hallenplan wird als Asset aus Contentful geladen
 * 
 * Besonderheiten:
 * - Responsive Design für alle Bildschirmgrößen
 * - Modal-Dialog für den Hallenplan
 * - Dynamische Bildgrößenanpassung
 * - Lazy Loading für optimierte Performance
 */

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Badge, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import client from '../config/contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import Sponsors from './Sponsors';
import SEO from './SEO'; 
import './Home.css';
import './Modal.css';

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node) => {
      const text = node.content.map(item => item.value).join('');
      return <p className="simple-text">{text}</p>;
    }
  }
};

const Home = () => {
  const [homeContent, setHomeContent] = useState(null);
  const [latestNews, setLatestNews] = useState([]);
  const [sportAngebote, setSportAngebote] = useState([]);
  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedNews, setSelectedNews] = useState(null);
  const [showHallenplan, setShowHallenplan] = useState(false);
  const [hallenplanBild, setHallenplanBild] = useState(null);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const [homeEntry, newsResponse, sportResponse, hallenplanResponse] = await Promise.all([
          client.getEntry('5RgAsuJ0V9EbATgMlpfJJc'),
          client.getEntries({
            content_type: 'news',
            order: '-fields.datum',
            limit: 2
          }),
          client.getEntries({
            content_type: 'sportangebot'
          }),
          client.getEntries({
            content_type: 'hallenplan',
            limit: 1
          })
        ]);

        setHomeContent(homeEntry);
        setLatestNews(newsResponse.items);
        setSportAngebote(sportResponse.items);
        
        if (hallenplanResponse.items?.[0]?.fields?.hallenplan) {
          setHallenplanBild(hallenplanResponse.items[0].fields.hallenplan);
        }
      } catch (error) {
        console.error('Fehler beim Laden der Inhalte:', error.message);
        // Hier könnte man einen Error-State setzen und dem Benutzer eine Fehlermeldung anzeigen
      }
    };

    fetchContent();
  }, []);

  return (
    <Container>
      <div>
        <SEO 
          title="Startseite"
          description="Willkommen beim TuS Jägersfreude - Ihr Sportverein in Saarbrücken. Erfahren Sie mehr über unsere Sportangebote, Veranstaltungen und Vereinsleben."
          keywords="TuS Jägersfreude, Sportverein Saarbrücken, Fußball Saarbrücken, Turnen Saarbrücken"
        />
      </div>
      <section className="welcome-section">
        <Row>
          <Col lg={8}>
            {homeContent?.fields.willkommen && (
              <div className="sport-card mb-4">
                {documentToReactComponents(homeContent.fields.willkommen, renderOptions)}
              </div>
            )}
            <div className="mt-4">
              <Button 
                variant="outline-success" 
                as={Link} 
                to="/kontakt" 
                className="me-3"
              >
                Kontakt aufnehmen
              </Button>
              <Button 
                variant="outline-success" 
                as={Link} 
                to="/verein" 
                className="me-3"
              >
                Mehr über uns
              </Button>
              <Button 
                variant="outline-success" 
                onClick={() => setShowHallenplan(true)}
              >
                Hallenplan TuS Jägersfreude
              </Button>
            </div>
          </Col>
          <Col lg={4} className="d-flex align-items-center justify-content-center mt-4 mt-lg-0">
            <div className="w-100">
              <h4 className="mb-3">Neuigkeiten</h4>
              {latestNews.map((news, index) => (
                <Card key={news.sys.id} className={`shadow-sm ${index > 0 ? 'mt-3' : ''}`}>
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-start mb-2">
                      <Badge bg="success">{news.fields.kategorie || 'Allgemein'}</Badge>
                      <small className="text-muted">
                        {new Date(news.fields.datum).toLocaleDateString()}
                      </small>
                    </div>
                    <Card.Title>{news.fields.titel}</Card.Title>
                    <Card.Text>{news.fields.kurzbeschreibung}</Card.Text>
                    <Button 
                      variant="link" 
                      className="text-success p-0"
                      onClick={() => setSelectedNews(news)}
                    >
                      Weiterlesen...
                    </Button>
                  </Card.Body>
                </Card>
              ))}
              <div className="text-center mt-3">
                <Button as={Link} to="/news" variant="outline-success" size="sm">
                  Alle Neuigkeiten anzeigen
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </section>

      <section className="sports-section">
        <h2 className="text-center mb-4">Unsere Sportangebote</h2>
        <Row>
          {sportAngebote.map((angebot) => (
            <Col md={4} className="mb-4" key={angebot.sys.id}>
              <div className="sport-card text-center h-100">
                <h3>{angebot.fields.titel}</h3>
                <p>{angebot.fields.kurzbeschreibung}</p>
                <Button 
                  variant="outline-success"
                  onClick={() => setSelectedSport(angebot)}
                >
                  Mehr erfahren
                </Button>
              </div>
            </Col>
          ))}
        </Row>
      </section>

      {/* Modal für Sportangebote */}
      <Modal
        show={!!selectedSport}
        onHide={() => setSelectedSport(null)}
        size="lg"
        centered
      >
        {selectedSport && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{selectedSport?.fields?.titel}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={7}>
                  <p className="lead mb-4">{selectedSport.fields.kurzbeschreibung}</p>
                  {selectedSport.fields.inhalt && 
                    documentToReactComponents(selectedSport.fields.inhalt, renderOptions)
                  }
                </Col>
                {selectedSport.fields.bild && (
                  <Col md={5} className="d-flex flex-column align-items-start justify-content-start">
                    <div className="sport-image-container mb-3">
                      <img
                        src={selectedSport.fields.bild.fields.file.url}
                        alt={`${selectedSport.fields.titel}`}
                        className="img-fluid shadow-sm sport-image"
                      />
                    </div>
                    {selectedSport.fields.bild2 && (
                      <div className="sport-image-container mb-3">
                        <img
                          src={selectedSport.fields.bild2.fields.file.url}
                          alt={`${selectedSport.fields.titel} - Bild 2`}
                          className="img-fluid shadow-sm sport-image"
                        />
                      </div>
                    )}
                    {selectedSport.fields.bild3 && (
                      <div className="sport-image-container">
                        <img
                          src={selectedSport.fields.bild3.fields.file.url}
                          alt={`${selectedSport.fields.titel} - Bild 3`}
                          className="img-fluid shadow-sm sport-image"
                        />
                      </div>
                    )}
                  </Col>
                )}
              </Row>
            </Modal.Body>
          </>
        )}
      </Modal>

      {/* Sponsors Section */}
      <Sponsors />

      {/* Modal für News */}
      <Modal
        show={!!selectedNews}
        onHide={() => setSelectedNews(null)}
        size="lg"
        centered
      >
        {selectedNews && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{selectedNews?.fields?.titel}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex justify-content-between mb-3">
                <Badge bg="success">{selectedNews.fields.kategorie || 'Allgemein'}</Badge>
                <small className="text-muted">
                  {new Date(selectedNews.fields.datum).toLocaleDateString()}
                </small>
              </div>
              <Row>
                <Col md={7}>
                  {selectedNews.fields.inhalt && 
                    documentToReactComponents(selectedNews.fields.inhalt, renderOptions)
                  }
                </Col>
                <Col md={5}>
                  {selectedNews.fields.bild && (
                    <div className="sport-image-container mb-3">
                      <img
                        src={selectedNews.fields.bild.fields.file.url}
                        alt={selectedNews.fields.titel}
                        className="img-fluid shadow-sm sport-image"
                      />
                    </div>
                  )}
                  {selectedNews.fields.bild2 && (
                    <div className="sport-image-container mb-3">
                      <img
                        src={selectedNews.fields.bild2.fields.file.url}
                        alt={`${selectedNews.fields.titel} - Bild 2`}
                        className="img-fluid shadow-sm sport-image"
                      />
                    </div>
                  )}
                  {selectedNews.fields.bild3 && (
                    <div className="sport-image-container mb-3">
                      <img
                        src={selectedNews.fields.bild3.fields.file.url}
                        alt={`${selectedNews.fields.titel} - Bild 3`}
                        className="img-fluid shadow-sm sport-image"
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </Modal.Body>
          </>
        )}
      </Modal>

      {/* Modal für Hallenplan */}
      <Modal
        show={showHallenplan}
        onHide={() => setShowHallenplan(false)}
        size="xl"
        centered
        dialogClassName="hallenplan-modal"
      >
        <Modal.Header closeButton style={{ border: 'none' }} />
        <Modal.Body className="position-relative">
          {hallenplanBild ? (
            <div className="text-center position-relative hallenplan-container">
              <img
                src={`https:${hallenplanBild.fields.file.url}`}
                alt="Hallenplan TuS Jägersfreude"
                className="img-fluid"
              />
            </div>
          ) : (
            <p>Hallenplan wird geladen...</p>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Home;
