import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar as BootstrapNavbar, Nav, Container } from 'react-bootstrap';
import { GiHamburgerMenu } from 'react-icons/gi';
import './Navbar.css';

const Navbar = () => {
  const location = useLocation();

  return (
    <BootstrapNavbar expand="lg" className="navbar">
      <Container>
        <BootstrapNavbar.Brand as={Link} to="/" className="d-lg-none"></BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav">
          <GiHamburgerMenu size={24} className="burger-icon" />
        </BootstrapNavbar.Toggle>
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" className={location.pathname === '/' ? 'active' : ''}>
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/news" className={location.pathname === '/news' ? 'active' : ''}>
              News
            </Nav.Link>
            <Nav.Link as={Link} to="/verein" className={location.pathname === '/verein' ? 'active' : ''}>
              Verein
            </Nav.Link>
            <Nav.Link as={Link} to="/bilder" className={location.pathname === '/bilder' ? 'active' : ''}>
              Bilder
            </Nav.Link>
            <Nav.Link as={Link} to="/kontakt" className={location.pathname === '/kontakt' ? 'active' : ''}>
              Kontakt
            </Nav.Link>
            <Nav.Link as={Link} to="/anfahrt" className={location.pathname === '/anfahrt' ? 'active' : ''}>
              Anfahrt
            </Nav.Link>
            <Nav.Link as={Link} to="/downloads" className={location.pathname === '/downloads' ? 'active' : ''}>
              Downloads
            </Nav.Link>
            <Nav.Link as={Link} to="/impressum" className={location.pathname === '/impressum' ? 'active' : ''}>
              Impressum
            </Nav.Link>
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};

export default Navbar;
