/**
 * Bilder Component - Bildergalerie des Vereins
 * 
 * Diese Komponente verwaltet die Bildergalerie des Vereins. Funktionen:
 * - Gruppierung der Bilder nach Jahren
 * - Responsive Grid-Layout für die Bildvorschau
 * - Modal für Vollbildansicht
 * - Navigation zwischen Bildern im Modal
 * 
 * Contentful Integration:
 * - Bilder werden aus dem 'bilder' Content Type geladen
 * - Metadaten: Titel, Jahr, Beschreibung
 * - Automatische Bildoptimierung durch Contentful
 * 
 * Technische Features:
 * - Lazy Loading für Bildvorschauen
 * - Optimierte Bildgrößen für verschiedene Bildschirme
 * - Touch-Support für Mobile Geräte
 * - Keyboard-Navigation im Modal (Pfeiltasten)
 */

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import client from '../config/contentful';
import OptimizedImage from './OptimizedImage';
import './Bilder.css';
import './Modal.css';

const Bilder = () => {
  const [groupedImages, setGroupedImages] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchBilder = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'bilder',
          order: '-fields.datum'
        });

        // Gruppiere Bilder nach Jahr
        const grouped = response.items.reduce((acc, image) => {
          if (image.fields.datum) {
            const year = new Date(image.fields.datum).getFullYear();
            if (!acc[year]) {
              acc[year] = [];
            }
            acc[year].push(image);
          }
          return acc;
        }, {});

        setGroupedImages(grouped);
      } catch (error) {
        console.error('Error fetching images:', error);
        setGroupedImages({});
      }
    };

    fetchBilder();
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  // Prüfe ob Bilder vorhanden sind
  const hasImages = Object.keys(groupedImages).length > 0;

  return (
    <Container className="bilder-container">
      <h1 className="text-center mb-4">Bildergalerie</h1>
      
      {!hasImages && (
        <p className="text-center">Keine Bilder gefunden.</p>
      )}

      {Object.entries(groupedImages)
        .sort(([yearA], [yearB]) => Number(yearB) - Number(yearA))
        .map(([year, images]) => (
          <div key={year} className="year-section mb-5">
            <h2 className="year-heading mb-3">{year}</h2>
            <Row>
              {images.map((image) => (
                <Col key={image.sys.id} xs={12} sm={6} md={4} lg={3} className="mb-4">
                  <div 
                    className="image-card"
                    onClick={() => handleImageClick(image)}
                  >
                    {image.fields.bild?.fields?.file?.url && (
                      <OptimizedImage
                        src={image.fields.bild.fields.file.url}
                        alt={image.fields.titel || 'Galeriebild'}
                        className="gallery-image"
                        effect="blur"
                        width={300}
                        height={200}
                        placeholderSrc={`${image.fields.bild.fields.file.url}?w=50&blur=100`}
                      />
                    )}
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        ))}

      <Modal
        show={selectedImage !== null}
        onHide={handleClose}
        size="xl"
        centered
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton />
        <Modal.Body style={{ padding: 0, position: 'relative' }}>
          {selectedImage?.fields?.bild?.fields?.file?.url && (
            <div style={{ 
              position: 'relative',
              width: '100%',
              margin: '0 auto'
            }}>
              <img
                src={selectedImage.fields.bild.fields.file.url}
                alt={selectedImage.fields.titel || 'Vergrößertes Bild'}
                style={{
                  width: 'calc(100% - 40px)',
                  maxHeight: 'calc(99vh - 40px)',
                  objectFit: 'contain',
                  display: 'block',
                  margin: '20px'
                }}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Bilder;
