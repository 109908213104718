/**
 * Hauptanwendung TuS Jägersfreude
 * 
 * Diese Komponente ist der Einstiegspunkt der Anwendung und definiert das Routing.
 * Sie verwendet React Router für die Navigation zwischen verschiedenen Seiten.
 * 
 * Routing-Struktur:
 * - / -> Home (Startseite)
 * - /verein -> Vereinsinformationen
 * - /news -> Neuigkeiten
 * - /bilder -> Bildergalerie
 * - /downloads -> Downloadbereich
 * - /kontakt -> Kontaktinformationen
 * - /anfahrt -> Anfahrtsbeschreibung
 * - /impressum -> Impressum
 * - /datenschutz -> Datenschutz
 * - /rechtliches -> Rechtliche Hinweise
 * 
 * Globale Komponenten:
 * - Navbar: Navigation (immer sichtbar)
 * - Footer: Fußzeile (immer sichtbar)
 */

import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import { useConfig } from './config';

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import LoginScreen from './components/LoginScreen';
import CookieBanner from './components/CookieConsent';
import SEO from './components/SEO';
import Legal from './components/Legal';

// Pages
import Home from './components/Home';
import News from './components/News';
import Verein from './components/Verein';
import Bilder from './components/Bilder';
import Downloads from './components/Downloads';
import Kontakt from './components/Kontakt';
import Anfahrt from './components/Anfahrt';
import Impressum from './components/Impressum';
import Datenschutz from './components/Datenschutz';

function App() {
  const config = useConfig();
  const [isLoggedIn, setIsLoggedIn] = useState(!config.enablePasswordProtection);

  // Aktualisiere den Login-Status, wenn sich die Konfiguration ändert
  useEffect(() => {
    setIsLoggedIn(!config.enablePasswordProtection);
  }, [config.enablePasswordProtection]);

  if (config.enablePasswordProtection && !isLoggedIn) {
    return <LoginScreen onLogin={() => setIsLoggedIn(true)} />;
  }

  return (
    <HelmetProvider>
      <Router>
        <div className="App d-flex flex-column min-vh-100">
          <SEO />
          <Header />
          <Navbar isLoggedIn={isLoggedIn} />
          <main className="flex-grow-1">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/verein" element={<Verein />} />
              <Route path="/news" element={<News />} />
              <Route path="/bilder" element={<Bilder />} />
              <Route path="/downloads" element={<Downloads />} />
              <Route path="/kontakt" element={<Kontakt />} />
              <Route path="/anfahrt" element={<Anfahrt />} />
              <Route path="/impressum" element={<Impressum />} />
              <Route path="/datenschutz" element={<Datenschutz />} />
              <Route path="/rechtliches" element={<Legal />} />
              <Route path="/login" element={<LoginScreen setIsLoggedIn={setIsLoggedIn} />} />
            </Routes>
          </main>
          <Footer />
          <CookieBanner />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
